.page-loading {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;

  .loading {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    color: #0a347f;
    font-size: 25px;
  }
}
