.page-loading.lazy-route-loading {
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;

  .loading {
    position: absolute;
  }
}
